export default{
    _TOKEN_KEY: 'token',
    _LANG_KEY: 'lang',
    _DEFAULT_LANG: 'hk',
    _MAINTENANCE: false,
    _API_URL: process.env.NODE_ENV === 'production'
            ? 'https://sdapi.mybuddhism.org/api/'
            : 'https://sdapi.mybuddhism.org/api/',
    _BASE_URL: process.env.NODE_ENV === 'production'
            ? 'https://tenjiku.org/#/'
            : 'https://tenjiku.org/#/',
}
